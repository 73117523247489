//@ts-ignore
import CHF from '../../svgs/ch_flag.svg';
//@ts-ignore
import DKK from '../../svgs/dk_flag.svg';
//@ts-ignore
import EUR from '../../svgs/eur_flag.svg';
//@ts-ignore
import GBP from '../../svgs/gb_flag.svg';
//@ts-ignore
import MAD from '../../svgs/morocco_flag.svg';
//@ts-ignore
import NOK from '../../svgs/no_flag.svg';
//@ts-ignore
import SEK from '../../svgs/se_flag.svg';
// @ts-ignore
import TRY from '../../svgs/tr_flag.svg';
//@ts-ignore
import AED from '../../svgs/uae_flag.svg';
//@ts-ignore
import USD from '../../svgs/us_flag.svg';
export const getCurrencyIcon = (currency: string | null) => {
  switch (currency) {
    case 'GBP':
      return GBP;
    case 'EUR':
      return EUR;
    case 'USD':
      return USD;
    case 'MAD':
      return MAD;
    case 'AED':
      return AED;
    case 'DKK':
      return DKK;
    case 'CHF':
      return CHF;
    case 'NOK':
      return NOK;
    case 'TRY':
      return TRY;
    case 'SEK':
      return SEK;

    default:
      return '';
  }
};
